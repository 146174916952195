

  @media (min-width: 501px) {
    .fader-text {
      transition: opacity 0.5s ease-in-out;
      opacity: 1;
      height: 108px;
      border-radius: 5px;
      font-weight: bold;
    }
}

@media (max-width: 500px) {
  .fader-text {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
    height: 100px;
    margin-bottom: 60px;
    font-weight: bold;
  }
}
  
  .fade-out {
    opacity: 0;
  }
  
  .fade-in {
    opacity: 1;
  }