@media (min-width: 501px) {
    #home-content-container {
        width: 60%; 
        margin: auto; 
        margin-bottom: 10px; 
    }
}

@media (max-width: 500px) {
    #home-content-container {
        width: 90%; 
        margin: auto; 
        padding-bottom: 20px; 
    }
}

.summary {
    text-align: left;
}

img {
    background: transparent;
    -moz-box-shadow: 0 0 5px rgba(0,0,0,0.2);
    
    
    position: relative;
  }