.home-content {
    width: 60%; 
    margin: auto;
    min-height: 300px;
    list-style-type: none;
    border-style: solid;
    border-top-color: #963;
    border-top-width: 1px;
    border-left: none;
    border-right: none;
    border-bottom: solid 3px #9BB9AB ;
    background-image: url('../../../../public/Assets/Images/Embleme.png');
    background-repeat: no-repeat;
    background-position: -69px 100px;
}

ul {
   text-align: left; 
   list-style-type: none;
   margin: 15;
   padding: 0;
}

a {text-decoration: none;}