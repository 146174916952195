.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {background-color: #f3f2ed}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dropdown {
  background-color: #fff;
}

#input-label-shrink {
  color: wheat;
  font-weight: bold;
  position: absolute; 
  top: -10px;  
  font-size: 20px;
}

.content {
  max-width: 150vh;
  margin: auto;
}

.page-paper {
  padding-top: 10px;
  padding-bottom: 40px;
  padding-right: 40px;
}

.result svg {
  position: relative;
  top: 7px

}

.chapter {
  text-align: left;
}

.chapter p  {
padding-left: 40px;
padding-right: 40px;
margin: 10px;
}
.chapter span  {
  padding-right: 10px;
  margin: 10px;
  }

 #chapter-header {
  text-align: center;
 }

 .chapter-verse-number {
  text-align: center;
 }

 
.MuiSelect-select {background-color: aliceblue;}
.banner {
  background-image: url('../public/Assets/Images/open-bible.png');
  background-repeat: no-repeat;
  background-position: calc(50%) -20px;
  min-height: 100px; 
  width: 100%; 
  color: black; 
  background-color: #f3f2ed;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  background-color: azure;
}
.main {
  margin: auto;
  max-width: 120vh;
  padding: 10px;
}

.search-options {
  margin: auto;
  background: linear-gradient(#9f4d4f,#a23021,#a23021 200%,#a23021 5%);
    border-style: solid;
    border-top-color: #963;
    border-top-width: 1px;
    border-left: none;
    border-right: none;
    border-bottom: solid 3px #9BB9AB ;
}

.search-button {
border: none;
position: relative; 
top: 5px;
border-radius: 5px;
}

#header-title  {
  margin: 0 40px 0 0;
  font-family: "Times New Roman", Times, serif;
  font-size: 54px;
  color: rgba(30, 47, 79, 0.8);
text-shadow: 2px 2px #9BB9AB, 0 0 0 #000, 1px 4px 6px #def;
}

#sub-title {
  text-align: center;
  font-size: 24px;
  margin: 0;
  color: #000;
  padding-bottom: 10px;
  position: relative; 
}



@media (min-width: 500px) {
  #header-title {
  }
}

@media (max-width: 500px) {
  #header-title {
    padding-top: 20px;
  }
}

#top-menu ul li {display: inline-block; 
  padding-left:10px;
  padding-right:10px;
  right: 400px;
  margin: 0}

  #top-menu ul  {display: inline-block; 
    min-width: 50%;
    float: right; 
    position: relative; 
    margin: 0; 
  }

#top-menu {
  background: linear-gradient(90deg, rgba(162,48,33,1) 0%, #9F4D4F 55%, rgba(162,48,33,1) 92%);;
  min-height: 40px;
}

.header-menu {
  color: #F5DEB3;
  font-weight: bold;
}

.header-menu:hover {
    color:#fff;
    font-weight: bold;
  }

.next-previous-container {
  width: 100%; 
}

#previous-chapter {
      float: left; 
}

#next-chapter {
      float: right; 
    }

@media (max-width: 500px) {
  .result {
    max-width: 100%;
    margin: auto;
    min-height: 200px;
    
  
  }
}

@media (min-width: 501px) {
  .result {
    max-width: 83%;
    margin: auto;
    min-height: 200px;
    padding: 20px;
  
  }
}


.chapter {padding-top: 0px;}

#sign-up {
  color: #ffffff
}

.footer-container h5 {
  margin-top: 5px !important;
}

.footer-container {
  margin: auto;
  background: linear-gradient(90deg, #9F4D4F 0%, rgba(162,48,33,1) 55%, #9F4D4F 92%);
  border-left: none;
  border-right: none;
  border-top: solid 3px #9BB9AB;
  margin-top: 5px;
  min-height: 40px;
  box-shadow: 0 50vh 0 50vh #9F4D4F;
  color: wheat;
  width: 100vw;
}
.footer-container ul {
  margin-right: 20px;
  margin-left: 20px;
  height: 80px;
  text-align: center;
}

@media (min-width: 501px) {
  .footer-container ul {
    border-right: solid 1px wheat;
  }
}

@media (max-width: 500px) {
.footer-container ul {
  border-bottom: solid 1px wheat;
}
}



.footer-anchors {
  color: #fff
}

.footer-anchors:hover {
  color: wheat
}

